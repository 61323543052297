import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLogged: boolean = false;
  redirectUrl: string | undefined; // if user is not logged in user will be redirected to the accessed page after login
  route: string | undefined;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }



  // Example method to simulate user authentication
  login() {
    this.isLogged = true;
  }

  // Example method to simulate user logout
  logout() {
    this.isLogged = false;
  }

  // Example method to check if the user is authenticated
  isAuthenticated(): boolean {
    return this.isLogged;
  }

  // Example method to check user roles/permissions
  hasPermission(permission: string): boolean {
    // Check if the user has the required permission
    // Logic to check user permissions can vary based on your application
    return true; // For simplicity, always return true in this example
  }






  loginNewUser(email: string, password: string, contactNumber?: string) {
    let data: any = { };
    if(contactNumber){
      data = {'username': email, password, 'phoneNumber': contactNumber}
    } else {
      data = {'username': email, password}
    }
    return this.http.post(`${environment.apiUrl}Authentication/login`, data);
  }

  loginUser(email: string, password: string) {
    return this.http.post(`${environment.apiUrl}Authentication/login`, { 'username': email, password,  });
  }

  authMFA(email: string, password: string, authenticatorCode: number) {
    return this.http.post(`${environment.apiUrl}Authentication/authMFA?applications=RIMS140`, { 'username': email, password, authenticatorCode, "rememberMe": false  });
  }

  checkUser(email: string){
    return this.http.post(`${environment.apiUrl}Authentication/checkuser?userName=${email}`, null);
  }
  
  forgotpassword(email: string, redirecUrl: string){
    return this.http.post(`${environment.apiUrl}Authentication/forgotpassword`, {Email: email, ResetUrl: redirecUrl});
  }

  loadSharedKey(email: string){
    return this.http.post(`${environment.apiUrl}Authentication/loadmfasharedkey?userName=${email}`, null);
  }

  validateCode(email: string, twoFactorCode: any){
    return this.http.post(`${environment.apiUrl}Authentication/validatemfa`, { 'username': email, twoFactorCode });
  }
  
  changePassword(data: object){
    return this.http.post(`${environment.apiUrl}Authentication/passwordChange`, data);
  }
  resetPassword(data: object){
    return this.http.post(`${environment.apiUrl}Authentication/resetpassword`, data);
  }

  generateTokenForExternalApp(){
    return this.http.post(`${environment.apiUrl}account/generateExternalToken`, null);
  }
  
  generateApplicationToken(){
    return this.http.get(`${environment.appApiUrl}Account/GenerateApplicationToken`);
  }

  validateToken() {
    return this.http.get(`${environment.apiUrl}Account/ValidatedToken`);
  }

  refreshSSOToken(token: string, tokenReceivedFromApplication?: string) {
    const data = {
      'accessToken':token, 
      tokenReceivedFromApplication
    }
    return this.http.post(`${environment.apiUrl}Account/refreshtoken`, data);
  }
  
  newSSOToken(token: string, refreshToken?: string) {
    const data = {
      'Token':token, 
      'RefreshToken':refreshToken
    }
    return this.http.post(`${environment.apiUrl}Authentication/newssotoken`, data);
  }


  generateTokenClaim(data: object){
    return this.http.post(`${environment.apiUrl}Authentication/generatetokenclaim`, data);
  }

  getUsersList(){
    return this.http.get(`${environment.apiUrl}UserList`);
  }

  handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.status === 401) {
      this.signOut();
    } else {
      this.signOut();
    }
    // console.log(errorResponse);
  }  

  signOut() {
    localStorage.clear();
    this.isLogged = false;
    this.router.navigate(['/']);
  }
}
