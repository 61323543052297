import { Injectable } from "@angular/core"; 
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { CommonService } from "./common.service";
import { AuthService } from "./auth.service";

@Injectable()

export class AuthGuardService implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router,
        private commonService: CommonService
    ) { 
     }

     canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // Check if the user is authenticated
        if (this.authService.isAuthenticated()) {
          // Check if the user has the required permissions (if needed)
          // Example: const requiredPermission = next.data.permission;
          //          if (!this.authService.hasPermission(requiredPermission)) {
          //            return false;
          //          }
    
          // User is authenticated and authorized, allow access to the route
          return true;
        } else {
          // User is not authenticated, redirect to the login page
          this.router.navigate(['/login']);
          return false;
        }
      }
}