export enum AlertType {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
    Question = 'question'
}

export const UserTypes = {
  new : 'NEW',
  mfa: 'MFA',
  sso: 'SSO'
}

export const NotificationType = {
  error: 'error',
  success: 'success',
  warning: 'warning',
}

export interface ApiResponse {
  apiException: string;
  errors: any;
  message: string;
  result: any;
  statusCode: number;
  success?: boolean;
  isSuccessful?: boolean;
  totalRecords?: number;
}